import React, { Component } from "react";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class ChangeBegins extends Component {
  projectGsap = () => {
    gsap.registerPlugin(ScrollTrigger, SplitText);

    //Hero

    // gsap.from(".normal-header", 0.9, {
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     delay: 3.5,
    //   })
    gsap.from("h2.line span", 0.9, {
      y: 180,
      rotateZ: 6,
      ease: "Power3.easeOut",
      delay: 0.7,
      stagger: {
        amount: 0.2,
      },
    });
    gsap.from(".mobile-sunnybee-h4", 0.9, {
      y: 40,
      opacity: 0,
      ease: "Power3.easeOut",
      delay: 1.5,
      stagger: {
        amount: 0.2,
      },
    });
    gsap.set(".sunnybee-hero", { height: "100vh" });
    gsap.to(".sunnybee-hero", {
      height: "80vh",
      delay: 1,
      ease: "Power3.easeOut",
      duration: 1,
    });
    gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
    ScrollTrigger.batch(".sunnybee-hero-listitem li", {
      onEnter: (batch) => {
        gsap.from(batch, {
          duration: 1,
          opacity: 0,
          delay: 1.2,
          yPercent: 100,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.1,
          visibility: "visible",
        });
      },
      toggleActions: "play none none none",
      once: true,
      markers: false,
    });
    gsap.set(".sunnybee-cover", { opacity: 1 });
    gsap.to(".sunnybee-cover", {
      opacity: 0,
      delay: 0,
      ease: "Power3.easeIn",
      duration: 0.3,
    });
    gsap.set(".sunnybee-heading", { opacity: 0 });
    gsap.to(".sunnybee-heading", {
      opacity: 1,
      delay: 0.1,
      ease: "Power3.easeIn",
      duration: 1,
    });
    gsap.set(".sunnybee-hero-cont", { "margin-bottom": 0 });
    gsap.to(".sunnybee-hero-cont", {
      "margin-bottom": 90,
      delay: 1,
      ease: "Power3.linear",
      duration: 1.5,
    });
    gsap.set(".our-approach-gra", { xPercent: 100 });
    gsap.to(".our-approach-gra", {
      xPercent: 0,
      delay: 2,
      ease: "Power3.easeOut",
      duration: 1.5,
    });
    gsap.set(".project-text", { visibility: "hidden" });
    ScrollTrigger.batch(".project-text", {
      onEnter: (batch) => {
        gsap.from(batch, {
          duration: 1.5,
          delay: 0,
          yPercent: 50,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.4,
          visibility: "visible",
        });
      },
      toggleActions: "play none none none",
      once: true,
      start: "top 80%",
      end: "top 40%",
      markers: false,
    });

    gsap.set(".bdr-top", { width: "0%" });
    gsap.to(".bdr-top", {
      width: "100%",
      ease: "Power3.easeOut",
      duration: 1,
      scrollTrigger: {
        trigger: ".bdr-top",
        start: "top 80%",
      },
    });
  };

  componentDidMount() {
    this.projectGsap();
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Change Begins | Fingerprints Future</title>
          <meta
            name="description"
            content="Revamp Change Begins brand tone and aesthetic. Utilized design thinking to redesign the website with improved navigation and fluid animations"
          />
        </Helmet>

        <style>
          {`.home-work-parent h3 {
                    color: #000;
                }`}
        </style>
        <div className="home-cover-out"></div>
        <div className="overflow-hidden">
          <div className="sunnybee-cover"></div>
          {/* Savittr Hero */}
          <div className="sunnybee-hero">
            {/* <div className="our-approach-gra"></div> */}
            <div className="container sunnybee-hero-cont">
              <div className="row">
                <div className="col-lg-9">
                  <div>
                    <h2 className="sunnybee-heading">CHANGE BEGINS</h2>
                  </div>
                  <div className="d-none d-lg-block d-md-block">
                    <div>
                      <h2 className="line">
                        <span>Suit Up for Success:</span>
                      </h2>
                    </div>
                    <div>
                      <h2 className="line">
                        <span>Where Ambition Meets Action</span>
                      </h2>
                    </div>
                  </div>
                  <div className="d-block d-lg-none d-md-none">
                    <h4 className="mobile-sunnybee-h4">
                      Suit Up for Success: Where Ambition Meets Action
                    </h4>
                  </div>
                </div>
                <div className="col-lg-3 sunnybee-hero-listitem">
                  <ul>
                    <li>Wireframing</li>
                    <li>User Experience Design (UX)</li>
                    <li>User Interface Design (UI)</li>
                    <li>Rapid Prototyping</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* ocacademy Hero End */}

          <div className="container sunnybee-video1 hide-nav">
            <img
              alt="Fingerprints Future"
              className="img-fluid d-lg-block d-none"
              src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/changebegins-img1-1.png"
            />
            <img
              alt="Fingerprints Future"
              className="img-fluid d-lg-none d-block "
              src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/changebegins-img1-1.png"
            />
          </div>

          {/* Mahasaghar About */}
          <div className="sunnybee-about">
            <div className="d-none d-lg-block sunnybee-gra1"></div>
            <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
            <div className="container sunnybee-about-cont">
              <div className="row">
                <div className="col-lg-1 d-lg-block d-md-none"></div>
                <div className="col-lg-5 col-md-6">
                  <div>
                    <h4 className="project-text">What went into the</h4>
                  </div>
                  <div>
                    <h4 className="project-text">creation of the app?</h4>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div>
                    <p className="project-text">
                      When Change Begins came to us, they aimed to develop a
                      program that would empower professionals to become
                      career-ready and reach their full potential. At Future, we
                      embarked on this journey by blending strategic creativity
                      and functionality to make this vision a reality.
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 d-lg-block d-md-none"></div>
              </div>
            </div>
          </div>
          {/* Savittr About End */}

          {/* <div className='container sunnybee-video1 project-text mahasaghar-video'>
                <img alt="Fingerprints Future" className='img-fluid d-lg-block d-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/gsis-img2.png"  />
                <img alt="Fingerprints Future" className='img-fluid d-lg-none d-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/gsis-img2.png"  />
            </div> */}
        </div>

        <div className="container savittr-video1">
          {/* <img alt="Fingerprints Future" className='img-fluid' src={Img2} /> */}
          <video
            className="project-text"
            src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/changebegins-video1-2.mp4"
            muted
            autoPlay={"autoplay"}
            preload="auto"
            loop
            playsInline
            controlsList="nodownload"
          />
        </div>

        <div className="container futbank-rd ew-resp para-width">
          <div className="row savittr-creative-colr">
            <div className="col-lg-1 col-md-1"></div>
            <div className="col-lg-4 col-md-5 align-self-center">
              <h4 className="project-text">
                Written Language <br /> Transformation:
              </h4>
              <p className="project-text">
                To make the app’s messaging more appealing and relevant, we
                rewrote the content with a focus on clarity, motivation, and
                inclusivity. The goal was to create a language that speaks
                directly to professionals, encouraging them to embrace their
                career paths with confidence.
              </p>
            </div>
            <div className="col-lg-1 col-md-1"></div>
            <div className="col-lg-5 col-md-5 futbank-img6 resp-img-width savittr-video3">
              {/* <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-5.png" /> */}
              <video
                className="project-text"
                src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/changebegins-video2-2.mp4"
                muted
                autoPlay={"autoplay"}
                preload="auto"
                loop
                playsInline
                controlsList="nodownload"
              />
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>

        <div className="container sunnybee-video1 hide-nav project-text">
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-block d-none"
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/changebegins-img2.png"
          />
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-none d-block "
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/changebegins-img2.png"
          />
        </div>

        <div className="container futbank-rd ew-resp para-width">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-5 col-md-5 futbank-img8 resp-img-width savittr-video3">
              {/* <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-7.png" /> */}
              <video
                className="project-text"
                src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/changebegins-video3-2.mp4"
                muted
                autoPlay={"autoplay"}
                preload="auto"
                loop
                playsInline
                controlsList="nodownload"
              />
            </div>
            <div className="col-lg-1 col-md-1"></div>
            <div className="col-lg-4 col-md-5 align-self-center">
              <h4 className="project-text">
                Creation of <br /> Visual Language:
              </h4>
              <p className="project-text">
                We crafted a set of vibrant 2D avatars that professionals could
                relate to. These avatars symbolize various stages of career
                growth, offering users a visual companion as they embark on
                their development journey. We wanted the design to feel
                welcoming and motivational, creating a platform that’s visually
                engaging and inspiring.
              </p>
            </div>
            <div className="col-lg-1 col-md-1"></div>
          </div>
        </div>

        <div className="container sunnybee-video1 hide-nav project-text">
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-block d-none"
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/changebegins-img3.png"
          />
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-none d-block "
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/changebegins-img3.png"
          />
        </div>

        <div className="sunnybee-about">
          <div className="d-none d-lg-block sunnybee-gra1"></div>
          <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
          <div className="container sunnybee-about-cont">
            <div className="row">
              <div className="col-lg-1 d-lg-block d-md-none"></div>
              <div className="col-lg-5 col-md-6 txt-break">
                <div className="d-none d-xl-block">
                  <h4 className="project-text">Design and Development:</h4>
                </div>
                <div className="d-block d-xl-none">
                  <h4 className="project-text">Design and Development:</h4>
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div>
                  <p className="project-text">
                    Our design process began with detailed wireframes to map out
                    user flows and interactions, ensuring a smooth and intuitive
                    experience. With a colorful and dynamic UI, we focused on
                    making the platform engaging, allowing users to navigate
                    their career readiness journey effortlessly. Once the design
                    was finalized, we developed the app, bringing all elements
                    together into a cohesive and functional platform.
                  </p>
                </div>
              </div>
              <div className="col-lg-1 d-lg-block d-md-none"></div>
            </div>
          </div>
        </div>

        <div className="container sunnybee-video1 hide-nav project-text">
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-block d-none"
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/changebegins-img4.png"
          />
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-none d-block "
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/changebegins-img4.png"
          />
        </div>

        <div className="container futbank-rd ew-resp para-width">
          <div className="row savittr-creative-colr">
            <div className="col-lg-1 col-md-1"></div>
            <div className="col-lg-4 col-md-5 align-self-center">
              <h4 className="project-text">
                Not Your Average <br /> Career Coach
              </h4>
              <p className="project-text">
                When Change Begins approached us, we knew this wasn’t going to
                be your typical career platform. So, we set out to create
                something remarkable—an experience that blends guidance with
                style, where users don’t just prepare for careers, but are
                transformed into powerhouse professionals. At Future, we took
                pride in delivering a platform that goes above and beyond,
                making career prep as vibrant and unique as the people it
                serves.
              </p>
            </div>
            <div className="col-lg-1 col-md-1"></div>
            <div className="col-lg-5 col-md-5 futbank-img6 resp-img-width savittr-video3">
              {/* <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-5.png" /> */}
              <video
                className="project-text"
                src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/changebegins-video4-1.mp4"
                muted
                autoPlay={"autoplay"}
                preload="auto"
                loop
                playsInline
                controlsList="nodownload"
              />
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>

        <div className="container sunnybee-about-img mahasaghar-img-padding">
          <div className="row">
            <div className="col-lg-6 col-md-6 img-b-padding">
              <img
                alt="Fingerprints Future"
                className="img-fluid project-text"
                src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/changebegins-img5.png"
              />
            </div>
            <div className="col-lg-6 col-md-6">
              <img
                alt="Fingerprints Future"
                className="img-fluid project-text"
                src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/changebegins-img6.png"
              />
            </div>
          </div>
        </div>

        {/* Work Section */}
        <div className="sunnybee-work">
          <div className="container fixed-work-cont">
            <div className="bdr-top"></div>
            <h4 className="project-text">You might also like</h4>
            <div className="row home-work-parent">
              <div className="col-lg-6 col-md-6 home-work-child workanimation">
                <a className="ocacademy-link" href="/work/oc-academy"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/ocacademy-img-cover-sm.png"
                />
                <h3>OC Academy</h3>
                <p>Website Design</p>
              </div>
              <div className="col-lg-6 col-md-6 home-work-child workanimation">
                <a className="mahasaghar-link" href="/work/averlon"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/averlon-cover.png"
                />
                <h3>Averlon Cloud Security</h3>
                <p>Website Design & Development</p>
              </div>
            </div>
          </div>
        </div>
        {/* Work Section End*/}

        <Footer />
      </>
    );
  }
}
