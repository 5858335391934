import React, { Component } from "react";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";

export default class Averlon extends Component {
  projectGsap = () => {
    gsap.registerPlugin(ScrollTrigger, SplitText);

    //Hero

    // gsap.from(".normal-header", 0.9, {
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     delay: 3.5,
    //   })
    gsap.from("h2.line span", 0.9, {
      y: 180,
      rotateZ: 6,
      ease: "Power3.easeOut",
      delay: 0.7,
      stagger: {
        amount: 0.2,
      },
    });
    gsap.from(".mobile-sunnybee-h4", 0.9, {
      y: 40,
      opacity: 0,
      ease: "Power3.easeOut",
      delay: 1.5,
      stagger: {
        amount: 0.2,
      },
    });
    gsap.set(".sunnybee-hero", { height: "100vh" });
    gsap.to(".sunnybee-hero", {
      height: "80vh",
      delay: 1,
      ease: "Power3.easeOut",
      duration: 1,
    });
    gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
    ScrollTrigger.batch(".sunnybee-hero-listitem li", {
      onEnter: (batch) => {
        gsap.from(batch, {
          duration: 1,
          opacity: 0,
          delay: 1.2,
          yPercent: 100,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.1,
          visibility: "visible",
        });
      },
      toggleActions: "play none none none",
      once: true,
      markers: false,
    });
    gsap.set(".sunnybee-cover", { opacity: 1 });
    gsap.to(".sunnybee-cover", {
      opacity: 0,
      delay: 0,
      ease: "Power3.easeIn",
      duration: 0.3,
    });
    gsap.set(".sunnybee-heading", { opacity: 0 });
    gsap.to(".sunnybee-heading", {
      opacity: 1,
      delay: 0.1,
      ease: "Power3.easeIn",
      duration: 1,
    });
    gsap.set(".sunnybee-hero-cont", { "margin-bottom": 0 });
    gsap.to(".sunnybee-hero-cont", {
      "margin-bottom": 90,
      delay: 1,
      ease: "Power3.linear",
      duration: 1.5,
    });
    gsap.set(".our-approach-gra", { xPercent: 100 });
    gsap.to(".our-approach-gra", {
      xPercent: 0,
      delay: 2,
      ease: "Power3.easeOut",
      duration: 1.5,
    });
    gsap.set(".project-text", { visibility: "hidden" });
    ScrollTrigger.batch(".project-text", {
      onEnter: (batch) => {
        gsap.from(batch, {
          duration: 1.5,
          delay: 0,
          yPercent: 50,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.4,
          visibility: "visible",
        });
      },
      toggleActions: "play none none none",
      once: true,
      start: "top 80%",
      end: "top 40%",
      markers: false,
    });

    gsap.set(".bdr-top", { width: "0%" });
    gsap.to(".bdr-top", {
      width: "100%",
      ease: "Power3.easeOut",
      duration: 1,
      scrollTrigger: {
        trigger: ".bdr-top",
        start: "top 80%",
      },
    });
  };

  componentDidMount() {
    this.projectGsap();
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Averlon Cloud Security | Fingerprints Future</title>
          <meta
            name="description"
            content="Revamp Averlon Cloud Security brand tone and aesthetic. Utilized design thinking to redesign the website with improved navigation and fluid animations"
          />
        </Helmet>

        <style>
          {`.home-work-parent h3 {
                    color: #000;
                }`}
        </style>
        <div className="home-cover-out"></div>
        <div className="overflow-hidden">
          <div className="sunnybee-cover"></div>
          {/* Savittr Hero */}
          <div className="sunnybee-hero">
            {/* <div className="our-approach-gra"></div> */}
            <div className="container sunnybee-hero-cont">
              <div className="row">
                <div className="col-lg-9">
                  <div>
                    <h2 className="sunnybee-heading">Averlon Cloud Security</h2>
                  </div>
                  <div className="d-none d-lg-block d-md-block">
                    <div>
                      <h2 className="line">
                        <span>Crafting a Secure Future: The</span>
                      </h2>
                    </div>
                    <div>
                      <h2 className="line">
                        <span>Averlon Cloud Security Website</span>
                      </h2>
                    </div>
                  </div>
                  <div className="d-block d-lg-none d-md-none">
                    <h4 className="mobile-sunnybee-h4">
                      Crafting a Secure Future: The Averlon Cloud Security
                      Website
                    </h4>
                  </div>
                </div>
                <div className="col-lg-3 sunnybee-hero-listitem">
                  <ul>
                    <li>User Research</li>
                    <li>Personas</li>
                    <li>User Flows</li>
                    <li>Wire-framing</li>
                    <li>User Experience Design (UX)</li>
                    <li>User Interface Design (UI)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* ocacademy Hero End */}

          <div className=" sunnybee-video1 hide-nav">
            <img
              alt="Fingerprints Future"
              className="img-fluid d-lg-block d-none"
              src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/averlon-img1.png"
            />
            <img
              alt="Fingerprints Future"
              className="img-fluid d-lg-none d-block "
              src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/averlon-img1.png"
            />
          </div>

          {/* Mahasaghar About */}
          <div className="sunnybee-about">
            <div className="d-none d-lg-block sunnybee-gra1"></div>
            <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
            <div className="container sunnybee-about-cont">
              <div className="row">
                <div className="col-lg-1 d-lg-block d-md-none"></div>
                <div className="col-lg-5 col-md-6">
                  <div>
                    <h4 className="project-text">What went into the</h4>
                  </div>
                  <div>
                    <h4 className="project-text">creation of the website?</h4>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div>
                    <p className="project-text">
                      Creating the Averlon Cloud Security website was a
                      meticulous endeavor. We started with detailed wireframes
                      and a sleek design, then elevated it by integrating
                      animated 3D objects to showcase Averlon’s cutting-edge
                      technology. The result? A visually compelling site that
                      communicates Averlon’s dedication to advanced online
                      security with clarity.
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 d-lg-block d-md-none"></div>
              </div>
            </div>
          </div>
          {/* Savittr About End */}

          {/* <div className='container sunnybee-video1 project-text mahasaghar-video'>
                <img alt="Fingerprints Future" className='img-fluid d-lg-block d-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/gsis-img2.png"  />
                <img alt="Fingerprints Future" className='img-fluid d-lg-none d-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/gsis-img2.png"  />
            </div> */}
        </div>

        <div className="container sunnybee-about-img mahasaghar-img-padding averlon-img2-3">
          <div className="row">
            <div className="col-lg-6 col-md-6 img-b-padding">
              <img
                alt="Fingerprints Future"
                className="img-fluid project-text"
                src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/averlon-img2.png"
              />
            </div>
            <div className="col-lg-6 col-md-6">
              <img
                alt="Fingerprints Future"
                className="img-fluid project-text"
                src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/averlon-img3.png"
                style={{ height: "auto" }}
              />
            </div>
          </div>
        </div>

        <div className="sunnybee-about">
          <div className="d-none d-lg-block sunnybee-gra1"></div>
          <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
          <div className="container sunnybee-about-cont">
            <div className="row">
              <div className="col-lg-1 d-lg-block d-md-none"></div>
              <div className="col-lg-5 col-md-6">
                <div>
                  <h4 className="project-text">Bringing Averlon Cloud </h4>
                </div>
                <div>
                  <h4 className="project-text">Security to Life</h4>
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div>
                  <p className="project-text">
                    To illustrate Averlon's advanced security technologies
                    compellingly, we created and animated 3D objects. This
                    approach simplified complex concepts, making the technology
                    more accessible and engaging. We meticulously planned and
                    executed smooth, realistic animations synchronized with user
                    interactions. Combined with a sleek, modern interface and
                    interactive design elements, these enhancements resulted in
                    a visually appealing, highly functional website that
                    effectively communicates Averlon's commitment to
                    cutting-edge online security.
                  </p>
                </div>
              </div>
              <div className="col-lg-2 d-lg-block d-md-none"></div>
            </div>
          </div>
        </div>

        <div className="container sunnybee-about-img mahasaghar-img-padding averlon-gif">
          <div className="row">
            <div className="col-lg-3 col-md-6 img-b-padding">
              <img
                alt="Fingerprints Future"
                className="img-fluid project-text"
                src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/averlon-gif1.gif"
              />
            </div>
            <div className="col-lg-3 col-md-6 img-b-padding">
              <img
                alt="Fingerprints Future"
                className="img-fluid project-text"
                src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/averlon-gif2.gif"
              />
            </div>
            <div className="col-lg-3 col-md-6 img-b-padding">
              <img
                alt="Fingerprints Future"
                className="img-fluid project-text"
                src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/averlon-gif3.gif"
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <img
                alt="Fingerprints Future"
                className="img-fluid project-text"
                src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/averlon-gif4.gif"
                style={{ height: "auto" }}
              />
            </div>
          </div>
        </div>

        <div className="sunnybee-video1 hide-nav project-text">
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-block d-none"
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/averlon-img4.png"
          />
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-none d-block "
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/averlon-img4.png"
          />
        </div>

        <div className="container sunnybee-video1 hide-nav averlon-video1">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <video
                className="project-text"
                src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/averlon-video1.mp4"
                muted
                autoPlay={"autoplay"}
                preload="auto"
                loop
                playsInline
                controlsList="nodownload"
              />
            </div>
            <div className="col-lg-1"></div>
          </div>
          {/* <div className="container savittr-video1"> */}
          {/* <img alt="Fingerprints Future" className='img-fluid' src={Img2} /> */}

          {/* </div> */}
        </div>

        <div className="averlon-slider">
          <Swiper
            style={{ width: "100%" }}
            spaceBetween={64}
            centeredSlides={true}
            slidesPerView={1.2}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            navigation={{
              nextEl: ".my-swiper-next",
              prevEl: ".my-swiper-prev",
            }}
            modules={[Autoplay, Navigation]}
            // breakpoints={{
            //   640: {
            //     slidesPerView: 1,
            //   },
            //   1080: {
            //     slidesPerView: 1,
            //   },
            //   1320: {
            //     slidesPerView: 1.1,
            //   },
            // }}
          >
            <SwiperSlide>
              <div>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/averlon-slider-img1.png"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/averlon-slider-img2.png"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/averlon-slider-img3.png"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        {/* Work Section */}
        <div className="sunnybee-work">
          <div className="container fixed-work-cont">
            <div className="bdr-top"></div>
            <h4 className="project-text">You might also like</h4>
            <div className="row home-work-parent">
              <div className="col-lg-6  home-work-child workanimation">
                <a className="mahasaghar-link" href="/work/change-begins"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/changebegins-cover.png"
                />
                <h3>Change Begins</h3>
                <p>Mobile App</p>
              </div>
              <div className="col-lg-6 home-work-child workanimation">
                <a className="mahasaghar-link" href="/work/axe-global"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/axeglobal-cover.png"
                />
                <h3>Axe Global</h3>
                <p>Website Design & Development</p>
              </div>
            </div>
          </div>
        </div>
        {/* Work Section End*/}

        <Footer />
      </>
    );
  }
}
