import React, { Component } from "react";
import Footer from "../components/footer";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";
import Featured from "../assets/blog-landing-img-2.png";

export default class BlogLanding extends Component {
  bloglandingGsap = () => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set(".workanimation", { visibility: "hidden" });
    ScrollTrigger.batch(".workanimation", {
      onEnter: (batch) => {
        gsap.from(batch, {
          duration: 1.5,
          delay: 0,
          yPercent: 50,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.4,
          visibility: "visible",
        });
      },
      toggleActions: "play none none none",
      once: true,
      start: "top 80%",
      end: "top 40%",
      markers: false,
    });
    gsap.set(".sunnybee-cover", { opacity: 1 });
    gsap.to(".sunnybee-cover", {
      opacity: 0,
      delay: 0,
      ease: "Power3.easeIn",
      duration: 0.3,
    });
  };

  componentDidMount() {
    this.bloglandingGsap();
  }

  render() {
    return (
      <div className="overflow-hidden v2-blog">
        <Helmet>
          <title>
            Discover the best articles on design thinking and UI/UX design |
            Fingerprints Future
          </title>
          <meta
            name="description"
            content="Top articles and blog posts on design, including product design blogs, design thinking blogs, thought leadership on design, and UI/UX design inspiration, all in one place."
          />
        </Helmet>
        {/* <div className="home-cover-out"></div>
        <div className="sunnybee-cover"></div> */}

        <style>
          {`.inner-page-btn {
                    display: none;
                }`}
        </style>
        {/* Blogs Hero */}
        <div className="work-hero">
          {/* <div className="d-none d-lg-block our-approach-gra"></div>
            <div className="d-block d-lg-none our-approach-gra-resp"></div> */}
          <div className="container work-hero-cont">
            <div>
              <h3 className="workanimation">What does the Future</h3>
            </div>
            <div>
              <h3 className="workanimation">hold? Find out here.</h3>
            </div>
          </div>
        </div>
        {/* Blogs Hero End */}

        {/* Blogs List */}
        <div className="blogs-post">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 workanimation">
                <a className="text-decoration-none" href="/blog/design-systems">
                  <div className="row blog-crds-bdr">
                    <div className="col-md-5 blog-featured-img">
                      <img
                        alt="Fingerprints Future"
                        className="img-fluid"
                        src={Featured}
                      />
                    </div>
                    <div className="col-md-7 align-self-center">
                      <div className="blog-featured-cont">
                        <div className="blog-chips-cover">
                          <div className="blog-chip">
                            <span>FEATURED</span>
                          </div>
                          <div className="blog-chip">
                            <span>UX DESIGN</span>
                          </div>
                        </div>
                        <h3>
                          Design Systems and <br /> Why They Matter
                        </h3>
                        <div className="blog-featured-tl">
                          <p>7 Minute read</p>
                          {/* <p>FEBRUARY 7, 2023 | BY FUTURE </p> */}
                          {/* <p>BY FUTURE </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              {/* New Blog The Secret to great ux */}
              <div className="col-xl-4 col-lg-6 col-md-6 blogs-post-cont">
                <a
                  className="text-decoration-none"
                  href="/blog/the-secret-to-great-ux">
                  <div className="home-work-child workanimation">
                    <div className="blog-crds-bdr">
                      <img
                        alt="Fingerprints Future"
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/Post-6%20Thumbnail.png?updatedAt=1731924545107"
                      />
                      <div className="blog-chips-cover">
                        <div className="blog-chip">
                          <span>UI Design</span>
                        </div>
                        <div className="blog-chip">
                          <span>UX Design</span>
                        </div>
                        {/* <div className="blog-chip">
                          <span>Interactive Technology</span>
                        </div> */}
                      </div>
                      <h3>
                        Personas: The Secret to Great UX, And Why We Won’t Skip
                        This Step
                      </h3>
                      <div className="blog-post-tl">
                        <p>8 Minute read</p>
                        {/* <p>June 16, 2023 | BY FUTURE </p> */}
                        {/* <p>By Aishwarya Daswani</p> */}
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              {/* New Blog Future 3D in UI Design */}
              <div className="col-xl-4 col-lg-6 col-md-6 blogs-post-cont">
                <a
                  className="text-decoration-none"
                  href="/blog/exploring-the-future-of-3d-in-ui-design">
                  <div className="home-work-child workanimation">
                    <div className="blog-crds-bdr">
                      <img
                        alt="Fingerprints Future"
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/future-3d-ui-design-thumbnail.png"
                      />
                      <div className="blog-chips-cover">
                        <div className="blog-chip">
                          <span>3D</span>
                        </div>
                        <div className="blog-chip">
                          <span>UI Design</span>
                        </div>
                        <div className="blog-chip">
                          <span>UX Design</span>
                        </div>
                        {/* <div className="blog-chip">
                          <span>Interactive Technology</span>
                        </div> */}
                      </div>
                      <h3>
                        From Pixels to Prototypes: Exploring the Future of 3D in
                        UI Design
                      </h3>
                      <div className="blog-post-tl">
                        <p>12 Minute read</p>
                        {/* <p>June 16, 2023 | BY FUTURE </p> */}
                        {/* <p>By Aishwarya Daswani</p> */}
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              {/* New Blog 5G Impact */}
              <div className="col-xl-4 col-lg-6 col-md-6 blogs-post-cont">
                <a
                  className="text-decoration-none"
                  href="/blog/5gs-impact-on-ui-ux-designing-for-lightning-fast-speeds">
                  <div className="home-work-child workanimation">
                    <div className="blog-crds-bdr">
                      <img
                        alt="Fingerprints Future"
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/post4-thumbnail-card.png?updatedAt=1730868267009"
                      />
                      <div className="blog-chips-cover">
                        <div className="blog-chip">
                          <span>5G-Optimized Interfaces</span>
                        </div>
                        <div className="blog-chip">
                          <span>UI Design</span>
                        </div>
                        <div className="blog-chip">
                          <span>UX Design</span>
                        </div>
                        {/* <div className="blog-chip">
                          <span>Interactive Technology</span>
                        </div> */}
                      </div>
                      <h3>
                        5G’s Impact on UI/UX: Designing for Lightning-Fast
                        Speeds
                      </h3>
                      <div className="blog-post-tl">
                        <p>9 Minute read</p>
                        {/* <p>June 16, 2023 | BY FUTURE </p> */}
                        {/* <p>By Aishwarya Daswani</p> */}
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              {/* New Blog */}
              <div className="col-xl-4 col-lg-6 col-md-6 blogs-post-cont">
                <a
                  className="text-decoration-none"
                  href="/blog/sustainable-ux-and-the-importance-of-digital-well-being">
                  <div className="home-work-child workanimation">
                    <div className="blog-crds-bdr">
                      <img
                        alt="Fingerprints Future"
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/Post-3-Sustainable-UX.jpg?updatedAt=1730091536245"
                      />
                      <div className="blog-chips-cover">
                        <div className="blog-chip">
                          <span>UI Design</span>
                        </div>
                        <div className="blog-chip">
                          <span>UX Design</span>
                        </div>
                        {/* <div className="blog-chip">
                          <span>Interactive Technology</span>
                        </div> */}
                      </div>
                      <h3>
                        Introduction: Sustainable UX and the Importance of
                        Digital Well-being
                      </h3>
                      <div className="blog-post-tl">
                        <p>8 Minute read</p>
                        {/* <p>June 16, 2023 | BY FUTURE </p> */}
                        {/* <p>By Aishwarya Daswani</p> */}
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              {/* New Blog */}
              <div className="col-xl-4 col-lg-6 col-md-6 blogs-post-cont">
                <a
                  className="text-decoration-none"
                  href="/blog/voice-ui-designing-for-a-screenless-future">
                  <div className="home-work-child workanimation">
                    <div className="blog-crds-bdr">
                      <img
                        alt="Fingerprints Future"
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/Voice-UI-card-img.jpg"
                      />
                      <div className="blog-chips-cover">
                        <div className="blog-chip">
                          <span>ARTIFICIAL INTELLIGENCE</span>
                        </div>
                        <div className="blog-chip">
                          <span>UX Design</span>
                        </div>
                        {/* <div className="blog-chip">
                          <span>Interactive Technology</span>
                        </div> */}
                      </div>
                      <h3>Voice UI: Designing for a Screenless Future</h3>
                      <div className="blog-post-tl">
                        <p>6 Minute read</p>
                        {/* <p>June 16, 2023 | BY FUTURE </p> */}
                        {/* <p>By Aishwarya Daswani</p> */}
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 blogs-post-cont">
                <a
                  className="text-decoration-none"
                  href="/blog/design-smarter-not-harder">
                  <div className="home-work-child workanimation">
                    <div className="blog-crds-bdr">
                      <img
                        alt="Fingerprints Future"
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/design-smarter.jpg"
                      />
                      <div className="blog-chips-cover">
                        <div className="blog-chip">
                          <span>ARTIFICIAL INTELLIGENCE</span>
                        </div>
                        <div className="blog-chip">
                          <span>UX Design</span>
                        </div>
                        {/* <div className="blog-chip">
                          <span>Interactive Technology</span>
                        </div> */}
                      </div>
                      <h3>
                        Design Smarter, Not Harder: The Role of AI in Creating
                        Custom User Experiences
                      </h3>
                      <div className="blog-post-tl">
                        <p>8 Minute read</p>
                        {/* <p>June 16, 2023 | BY FUTURE </p> */}
                        {/* <p>By Aishwarya Daswani</p> */}
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 blogs-post-cont">
                <a
                  className="text-decoration-none"
                  href="/blog/discovering-the-magic-of-spatial-input">
                  <div className="home-work-child workanimation">
                    <div className="blog-crds-bdr">
                      <img
                        alt="Fingerprints Future"
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-dtmsi-img.png"
                      />
                      <div className="blog-chips-cover">
                        <div className="blog-chip">
                          <span>SPATIAL INPUT</span>
                        </div>
                        <div className="blog-chip">
                          <span>Virtual Reality</span>
                        </div>
                        <div className="blog-chip">
                          <span>Interactive Technology</span>
                        </div>
                      </div>
                      <h3>
                        Discovering the Magic of Spatial Input: Interacting
                        Naturally with Your Eyes and Hands
                      </h3>
                      <div className="blog-post-tl">
                        <p>9 Minute read</p>
                        {/* <p>June 16, 2023 | BY FUTURE </p> */}
                        {/* <p>By Aishwarya Daswani</p> */}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 blogs-post-cont">
                <a
                  className="text-decoration-none"
                  href="/blog/using-heuristics-ui-ux-design">
                  <div className="home-work-child workanimation">
                    <div className="blog-crds-bdr">
                      <img
                        alt="Fingerprints Future"
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/blog-uh-img.png"
                      />
                      <div className="blog-chips-cover">
                        <div className="blog-chip">
                          <span>UI Design</span>
                        </div>
                        <div className="blog-chip">
                          <span>UX Design</span>
                        </div>
                      </div>
                      <h3>
                        Learning from Mario: Using Heuristics in UX /UI Design
                      </h3>
                      <div className="blog-post-tl">
                        <p>5 Minute read</p>
                        {/* <p>May 3, 2023 | BY FUTURE </p> */}
                        {/* <p>By Aishwarya Daswani</p> */}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 blogs-post-cont">
                <a className="text-decoration-none" href="/blog/ai-dumb-or-not">
                  <div className="home-work-child workanimation">
                    <div className="blog-crds-bdr">
                      <img
                        alt="Fingerprints Future"
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/blog-adn-img.png"
                      />
                      <div className="blog-chips-cover">
                        <div className="blog-chip">
                          <span>ARTIFICIAL INTELLIGENCE</span>
                        </div>
                        <div className="blog-chip">
                          <span>COGNITIVE PSYCHOLOGY</span>
                        </div>
                      </div>
                      <h3>
                        AI Isn't Making Us Dumb: The Positive Impact of Chat GPT
                        and Other Technologies
                      </h3>
                      <div className="blog-post-tl">
                        <p>3 Minute read</p>
                        {/* <p>APIRL 03, 2023 | BY FUTURE </p> */}
                        {/* <p>By Aishwarya Daswani</p> */}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 blogs-post-cont">
                <a
                  className="text-decoration-none"
                  href="/blog/chatbots-hot-or-not">
                  <div className="home-work-child workanimation">
                    <div className="blog-crds-bdr">
                      <img
                        alt="Fingerprints Future"
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/blog-chn-img.png"
                      />
                      <div className="blog-chips-cover">
                        <div className="blog-chip">
                          <span>USER EXPERIENCE</span>
                        </div>
                        <div className="blog-chip">
                          <span>ENGAGEMENT</span>
                        </div>
                        <div className="blog-chip">
                          <span>CONNECTION</span>
                        </div>
                      </div>
                      <h3>Chatbots AI - Hot or not?</h3>
                      <div className="blog-post-tl">
                        <p>7 Minute read</p>
                        {/* <p>MARCH 08, 2023 | BY FUTURE </p> */}
                        {/* <p>By Aishwarya Daswani</p> */}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 blogs-post-cont">
                <a
                  className="text-decoration-none"
                  href="/blog/why-gamification-works-in-digital-product-design">
                  <div className="home-work-child workanimation">
                    <div className="blog-crds-bdr">
                      <img
                        alt="Fingerprints Future"
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-smbi-img.png"
                      />
                      <div className="blog-chips-cover">
                        <div className="blog-chip">
                          <span>UX DESIGN</span>
                        </div>
                        <div className="blog-chip">
                          <span>USER RESEARCH</span>
                        </div>
                        <div className="blog-chip">
                          <span>PRODUCT DESIGN</span>
                        </div>
                      </div>
                      <h3>
                        Simple Models, Big Impact: Why Gamification Works in
                        Digital Product Design
                      </h3>
                      <div className="blog-post-tl">
                        <p>6 Minute read</p>
                        {/* <p>FEBRUARY 16, 2023 | BY FUTURE </p> */}
                        {/* <p>By Aishwarya Daswani</p> */}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 blogs-post-cont">
                <a
                  className="text-decoration-none"
                  href="/blog/the-importance-of-designing-for-usability">
                  <div className="home-work-child workanimation">
                    <div className="blog-crds-bdr">
                      <img
                        alt="Fingerprints Future"
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-du-img.png"
                      />
                      <div className="blog-chips-cover">
                        <div className="blog-chip">
                          <span>UX DESIGN</span>
                        </div>
                      </div>
                      <h3>
                        The Importance of Designing <br /> for usability in UI
                        and UX"
                      </h3>
                      <div className="blog-post-tl">
                        <p>7 Minute read</p>
                        {/* <p>JANUARY 24, 2023 | BY FUTURE </p> */}
                        {/* <p>By Aishwarya Daswani</p> */}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 blogs-post-cont">
                <a
                  className="text-decoration-none"
                  href="/blog/the-role-of-resarch-and-product-design">
                  <div className="home-work-child workanimation">
                    <div className="blog-crds-bdr">
                      <img
                        alt="Fingerprints Future"
                        className="img-fluid"
                        src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-rapd-img1.png"
                      />
                      <div className="blog-chips-cover">
                        <div className="blog-chip">
                          <span>USER RESEARCH</span>
                        </div>
                        <div className="blog-chip">
                          <span>PRODUCT DESIGN</span>
                        </div>
                      </div>
                      <h3>
                        Building Products <br /> People Love: The Role of <br />{" "}
                        Research in Product Design
                      </h3>
                      <div className="blog-post-tl">
                        <p>7 Minute read</p>
                        {/* <p>JANUARY 14, 2023 | BY FUTURE </p> */}
                        {/* <p>By Aishwarya Daswani</p> */}
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              {/* <div className="col-lg-4 col-md-6 blogs-post-cont home-work-child">
                        <div className='blog-crds-bdr'>
                            <img alt="Fingerprints Future" className="img-fluid" src={BlogL3} />
                            <div className='blog-chips-cover'>
                                <div className='blog-chip'><span>UX DESIGN</span></div>
                            </div>
                            <h3>How Artificial Intelligence <br /> Will Transform UI/UX Design</h3>
                            <div className='blog-post-tl'>
                                <p>7 Minute read</p>
                                <p>JANUARY 4, 2022 | BY FUTURE </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 blogs-post-cont home-work-child workanimation">
                        <div className='blog-crds-bdr'>
                            <img alt="Fingerprints Future" className="img-fluid" src={BlogL3} />
                            <div className='blog-chips-cover'>
                                <div className='blog-chip'><span>UX DESIGN</span></div>
                            </div>
                            <h3>How Artificial Intelligence <br /> Will Transform UI/UX Design</h3>
                            <div className='blog-post-tl'>
                                <p>7 Minute read</p>
                                <p>JANUARY 4, 2022 | BY FUTURE </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 blogs-post-cont home-work-child workanimation">
                        <div className='blog-crds-bdr'>
                            <img alt="Fingerprints Future" className="img-fluid" src={BlogL3} />
                            <div className='blog-chips-cover'>
                                <div className='blog-chip'><span>UX DESIGN</span></div>
                            </div>
                            <h3>How Artificial Intelligence <br /> Will Transform UI/UX Design</h3>
                            <div className='blog-post-tl'>
                                <p>7 Minute read</p>
                                <p>JANUARY 4, 2022 | BY FUTURE </p>
                            </div>
                        </div>
                    </div> */}
            </div>
          </div>
        </div>
        {/* Blogs List End */}

        <Footer />
      </div>
    );
  }
}
