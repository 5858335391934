import React, { Component } from "react";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class Sedin extends Component {
  projectGsap = () => {
    gsap.registerPlugin(ScrollTrigger, SplitText);

    //Hero

    // gsap.from(".normal-header", 0.9, {
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     delay: 3.5,
    //   })
    gsap.from("h2.line span", 0.9, {
      y: 180,
      rotateZ: 6,
      ease: "Power3.easeOut",
      delay: 0.7,
      stagger: {
        amount: 0.2,
      },
    });
    gsap.from(".mobile-sunnybee-h4", 0.9, {
      y: 40,
      opacity: 0,
      ease: "Power3.easeOut",
      delay: 1.5,
      stagger: {
        amount: 0.2,
      },
    });
    gsap.set(".sunnybee-hero", { height: "100vh" });
    gsap.to(".sunnybee-hero", {
      height: "80vh",
      delay: 1,
      ease: "Power3.easeOut",
      duration: 1,
    });
    gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
    ScrollTrigger.batch(".sunnybee-hero-listitem li", {
      onEnter: (batch) => {
        gsap.from(batch, {
          duration: 1,
          opacity: 0,
          delay: 1.2,
          yPercent: 100,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.1,
          visibility: "visible",
        });
      },
      toggleActions: "play none none none",
      once: true,
      markers: false,
    });
    gsap.set(".sunnybee-cover", { opacity: 1 });
    gsap.to(".sunnybee-cover", {
      opacity: 0,
      delay: 0,
      ease: "Power3.easeIn",
      duration: 0.3,
    });
    gsap.set(".sunnybee-heading", { opacity: 0 });
    gsap.to(".sunnybee-heading", {
      opacity: 1,
      delay: 0.1,
      ease: "Power3.easeIn",
      duration: 1,
    });
    gsap.set(".sunnybee-hero-cont", { "margin-bottom": 0 });
    gsap.to(".sunnybee-hero-cont", {
      "margin-bottom": 90,
      delay: 1,
      ease: "Power3.linear",
      duration: 1.5,
    });
    gsap.set(".our-approach-gra", { xPercent: 100 });
    gsap.to(".our-approach-gra", {
      xPercent: 0,
      delay: 2,
      ease: "Power3.easeOut",
      duration: 1.5,
    });
    gsap.set(".project-text", { visibility: "hidden" });
    ScrollTrigger.batch(".project-text", {
      onEnter: (batch) => {
        gsap.from(batch, {
          duration: 1.5,
          delay: 0,
          yPercent: 50,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.4,
          visibility: "visible",
        });
      },
      toggleActions: "play none none none",
      once: true,
      start: "top 80%",
      end: "top 40%",
      markers: false,
    });

    gsap.set(".bdr-top", { width: "0%" });
    gsap.to(".bdr-top", {
      width: "100%",
      ease: "Power3.easeOut",
      duration: 1,
      scrollTrigger: {
        trigger: ".bdr-top",
        start: "top 80%",
      },
    });
  };

  componentDidMount() {
    this.projectGsap();
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Sedin | Fingerprints Future</title>
          <meta
            name="description"
            content="Revamp Sedin brand tone and aesthetic. Utilized design thinking to redesign the website with improved navigation and fluid animations"
          />
        </Helmet>

        <style>
          {`.home-work-parent h3 {
                    color: #000;
                }`}
        </style>
        <div className="home-cover-out"></div>
        <div className="overflow-hidden">
          <div className="sunnybee-cover"></div>
          {/* Savittr Hero */}
          <div className="sunnybee-hero">
            {/* <div className="our-approach-gra"></div> */}
            <div className="container sunnybee-hero-cont">
              <div className="row">
                <div className="col-lg-9">
                  <div>
                    <h2 className="sunnybee-heading">SEDIN</h2>
                  </div>
                  <div className="d-none d-lg-block d-md-block">
                    <div>
                      <h2 className="line">
                        <span>Engineering a Website that</span>
                      </h2>
                    </div>
                    <div>
                      <h2 className="line">
                        <span>Works Like a Well-Oiled Machine</span>
                      </h2>
                    </div>
                  </div>
                  <div className="d-block d-lg-none d-md-none">
                    <h4 className="mobile-sunnybee-h4">
                      Engineering a Website that Works Like a Well-Oiled Machine
                    </h4>
                  </div>
                </div>
                <div className="col-lg-3 sunnybee-hero-listitem">
                  <ul>
                    <li>Wireframing</li>
                    <li>User Experience Design (UX)</li>
                    <li>User Interface Design (UI)</li>
                    <li>Rapid Prototyping</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* ocacademy Hero End */}

          <div className="container sunnybee-video1 hide-nav">
            <video
              className="project-text"
              src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sedin-video1.mp4"
              muted
              autoPlay={"autoplay"}
              preload="auto"
              loop
              playsInline
              controlsList="nodownload"
            />
            {/* <div className="container savittr-video1"> */}
            {/* <img alt="Fingerprints Future" className='img-fluid' src={Img2} /> */}

            {/* </div> */}
          </div>

          {/* Mahasaghar About */}
          <div className="sunnybee-about">
            <div className="d-none d-lg-block sunnybee-gra1"></div>
            <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
            <div className="container sunnybee-about-cont">
              <div className="row">
                <div className="col-lg-1 d-lg-block d-md-none"></div>
                <div className="col-lg-5 col-md-6">
                  <div>
                    <h4 className="project-text">What went into the</h4>
                  </div>
                  <div>
                    <h4 className="project-text">creation of the website?</h4>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div>
                    <p className="project-text">
                      Our team took a comprehensive approach to redesign Sedin
                      Engineering’s website, focusing on a seamless blend of
                      aesthetics and strategy. We implemented a fresh new look
                      and restructured the navigation to emphasize clarity and
                      ease of use. From wireframing to final design, our goal
                      was to create an informative and engaging experience that
                      highlights Sedin’s expertise and drives user engagement.
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 d-lg-block d-md-none"></div>
              </div>
            </div>
          </div>
          {/* Savittr About End */}

          {/* <div className='container sunnybee-video1 project-text mahasaghar-video'>
                <img alt="Fingerprints Future" className='img-fluid d-lg-block d-none' src="https://ik.imagekit.io/fingerprintsfuture/fps/gsis-img2.png"  />
                <img alt="Fingerprints Future" className='img-fluid d-lg-none d-block' src="https://ik.imagekit.io/fingerprintsfuture/fps/gsis-img2.png"  />
            </div> */}
        </div>

        <div className="container sunnybee-about-img mahasaghar-img-padding">
          <div className="row">
            <div className="col-lg-6 col-md-6 img-b-padding">
              <img
                alt="Fingerprints Future"
                className="img-fluid project-text"
                src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sedin-img1-1.png"
              />
            </div>
            <div className="col-lg-6 col-md-6">
              <img
                alt="Fingerprints Future"
                className="img-fluid project-text"
                src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sedin-img2-3.png"
              />
            </div>
          </div>
        </div>

        <div className="container savittr-video1">
          {/* <img alt="Fingerprints Future" className='img-fluid' src={Img2} /> */}
          <video
            className="project-text"
            src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sedin-video2-2.mp4"
            muted
            autoPlay={"autoplay"}
            preload="auto"
            loop
            playsInline
            controlsList="nodownload"
          />
        </div>

        <div className="container futbank-rd ew-resp para-width">
          <div className="row savittr-creative-colr">
            <div className="col-lg-1 col-md-1"></div>
            <div className="col-lg-4 col-md-5 align-self-center">
              <h4 className="project-text">Animated illustration </h4>
              <p className="project-text">
                To effectively convey Sedin’s cutting-edge solutions, we
                incorporated animated illustrations throughout the website.
                These animations help break down complex engineering concepts,
                offering users a dynamic, engaging way to learn about Sedin’s
                capabilities. By adding movement and interactivity, we were able
                to create a memorable experience that brings Sedin’s engineering
                excellence to the forefront.
              </p>
            </div>
            <div className="col-lg-1 col-md-1"></div>
            <div className="col-lg-5 col-md-5 futbank-img6 resp-img-width savittr-video3">
              {/* <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/cw-img-5.png" /> */}
              <video
                className="project-text"
                src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sedin-video3-1.mp4"
                muted
                autoPlay={"autoplay"}
                preload="auto"
                loop
                playsInline
                controlsList="nodownload"
              />
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>

        <div className="container sunnybee-video1 project-text">
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-block d-none"
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sedin-img3-1.png"
          />
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-none d-block "
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sedin-img3-1.png"
          />
        </div>
        <br />
        <br />
        <div className="container sunnybee-about-img mahasaghar-img-padding">
          <div className="row">
            <div className="col-lg-6 col-md-6 img-b-padding">
              <img
                alt="Fingerprints Future"
                className="img-fluid project-text"
                src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sedin-img4-1.png"
              />
            </div>
            <div className="col-lg-6 col-md-6">
              <img
                alt="Fingerprints Future"
                className="img-fluid project-text"
                src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sedin-img5-1.png"
              />
            </div>
          </div>
        </div>

        <div className="container sunnybee-video1 project-text">
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-block d-none"
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sedin-img6-2.png"
          />
          <img
            alt="Fingerprints Future"
            className="img-fluid d-lg-none d-block "
            src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sedin-img6-2.png"
          />
        </div>

        <div className="container futbank-rd ew-resp para-width">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-5 col-md-5 futbank-img8">
              <img
                alt="Fingerprints Future"
                className="img-fluid project-text"
                src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sedin-img7-1.png"
              />
            </div>
            <div className="col-lg-1 col-md-1"></div>
            <div className="col-lg-4 col-md-5 align-self-center">
              <h4 className="project-text">Customised icons</h4>
              <p className="project-text">
                To bring Sedin Engineering’s unique offerings to life, we
                designed a set of customized icons using a blend of geometric
                shapes. These icons visually communicate key features like
                hybrid work options and family benefits, making it easy for
                users to connect with Sedin's values at a glance. This distinct
                visual language not only supports the content but also enhances
                user engagement by adding a layer of clarity and
                personalization.
              </p>
            </div>
            <div className="col-lg-1 col-md-1"></div>
          </div>
        </div>

        {/* Work Section */}
        <div className="sunnybee-work">
          <div className="container fixed-work-cont">
            <div className="bdr-top"></div>
            <h4 className="project-text">You might also like</h4>
            <div className="row home-work-parent">
              <div className="col-lg-6 home-work-child workanimation">
                <a className="mahasaghar-link" href="/work/mahasaghar"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/mahasaghar-cover-sm.png"
                />
                <h3>Mahāsa Ghar</h3>
                <p>E-Commerce Website Design & Development</p>
              </div>
              <div className="col-lg-6 home-work-child workanimation">
                <a className="mahasaghar-link" href="/work/axe-global"></a>
                <img
                  alt="Fingerprints Future"
                  className="img-fluid"
                  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/axeglobal-cover.png"
                />
                <h3>Axe Global</h3>
                <p>Website Design & Development</p>
              </div>
            </div>
          </div>
        </div>
        {/* Work Section End*/}

        <Footer />
      </>
    );
  }
}
